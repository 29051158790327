import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import YouTube from "react-youtube";
import "./VideoCarousal.css";

const VideoCarousal = ({ video, images }) => {
  const imageList = images;
  console.log("video link : " + video);
  const carousalContent = [{ type: "youtube", id: video }];

  imageList.map((image) => {
    carousalContent.push({ type: "image", id: image });
    console.log(image);
  });

  const opts = {
    height: "360",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div
      className="video-carousal-container"
      style={{
        width: "100%",
        margin: "0 auto",
        border: "1px solid black",
        borderRadius: "6px",
        objectFit: "contain",
      }}
    >
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        navigation
        pagination={{ clickable: true }}
        loop
      >
        {carousalContent.map((item, index) => (
          <SwiperSlide key={index}>
            {item.type === "youtube" ? (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingTop: "56.25%", // 16:9 Aspect Ratio (adjust if needed)
                }}
              >
                <YouTube
                  videoId={item.id}
                  opts={{
                    width: "100%",
                    height: "100%",
                    playerVars: { autoplay: 0 },
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            ) : (
              <img
                src={item.id}
                alt={`Slide ${index + 1}`}
                style={{ width: "100%" }}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default VideoCarousal;
