import React from "react";
import "./Blogs.css";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { blogObjects } from "../../componet_objects/BlogObjects";

const Blogs = () => {
  return (
    <div className="blog-container">
      <Navbar />
      <div className="blog-section">
        <div className="blog-description">
          <h1>Documenting My Project To-Do Lists and Implementations</h1>
          <h2>
            <span>Project Name: </span>Tower Buster
          </h2>
        </div>
        <div>
          <div className="blog-filters"></div>
          <div className="blog-card-section">
            {blogObjects.map((obj, index) => {
              return (
                <div className="blog-card">
                  <div className="blog-card-heading">
                    <h3>{obj.title}</h3>
                  </div>
                  <div className="blog-content">
                    <ul>
                      {obj.content.map((list) => {
                        return <li>{list}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
