import React from "react";
import Navbar from "../../../navbar/Navbar";
import { useLocation } from "react-router-dom";
import "./DevSeven.css";
import VideoCarousal from "../../../video_carousal/VideoCarousal";
import cm1 from "../../../../images/GameModules/CameraHacking_1.jpg";
import cm2 from "../../../../images/GameModules/CameraHacking_2.jpg";
import cm3 from "../../../../images/GameModules/CameraHacking_3.jpg";
import cm4 from "../../../../images/GameModules/CameraHacking_4.jpg";
import tb1 from "../../../../images/GameModules/tower-buster-1.jpg";
import tb2 from "../../../../images/GameModules/tower-buster-2.jpg";
const modules = [
  {
    title: "Tower Buster",
    coreFeatures: [
      "A controllable tank with multi-directional movement with a shooting projectile mechanism. The tank’s turret can be moved with cursor positioning.",
      "Can have a boost while moving for a shorter amount of time and will be refueled after a certain amount of time.",
      "Different enemy towers with tracking player tanks and shooting projectile mechanisms.",
    ],
    development: [
      "Developed in Unreal Engine 5.4.",
      "BasePawn class created from which player-controllable Tank and AI Enemy Tower were inherited.",
      "Mesh rotation, fire, and destruction are all managed within the Base class, and they are inherited and overridden within the child classes.",
      "A distinct actor component controls health, which is passed down from the base class to the children's class. This allows for the addition of varying amounts of health to each distinct class.",
      "In addition to the health component, the player-controllable tank and enemy towers were given varying projectile damage amounts, which could be changed based on the player's progress and level of difficulty.",
      "The assigning and calling delegate functions in Unreal Engine handled causing damage by the projectiles.",
    ],
    furtherDevelopment: [
      "Creating a projectile upgrade menu and different visual aesthetics for the tank mesh.",
    ],
    // link: "https://www.youtube.com/embed/MGV_BSUor40",
    link: "m1O-RtMgVjc",
    images: [tb2, tb1],
  },
  {
    title: "Camera Hacking System (Inspired by Sleeping Dogs)",
    coreFeatures: [
      "Random code generation on each interaction, ensuring a unique challenge every time",
      "Color-coded hints to guide players in deciphering the correct code (green for correct, yellow for misplaced, and red for incorrect digits)",
      "Previous attempts tracker to assist players in their strategy",
      "Customizable code length (from 3 to 6 digits)",
      "Adjustable difficulty with a variable number of attempts",
      "Smooth player experience with real-time feedback on success or failure",
    ],
    development: [
      "Developed in Unreal Engine 5.4.",
      "Dynamic array to get hold of the player’s input and the correct sequence.",
      "The re-structuring of UI elements depends upon the designer’s input.",
      "With widget animation, the loading screen and movement of the user’s previous input were implemented.",
      "Binding Events in Animation to change UI elements in runtime.",
      "Instead of casting, interfaces are used to pass variables and activate events.",
    ],
    furtherDevelopment: [
      "Halfway through converting this into plugin content to publish and reuse in my upcoming projects as a feature.",
    ],
    link: "MGV_BSUor40",
    images: [cm1, cm2, cm3, cm4],
  },
];

const DevSeven = () => {
  const location = useLocation();
  return (
    <div className="dev_seven_container">
      <Navbar />
      <div className="main_section">
        <div className="dev-sev-intro">
          <h1 className="game_title">{location.state.object.title}</h1>
          <p>
            Here, I recreate and reimagine gameplay mechanics and UI/UX features
            inspired by the games I've played or watched. My approach is modular
            and dynamic, allowing for flexibility and originality. While I draw
            inspiration from existing games, my work isn't confined to
            replicating them—I also integrate my own ideas and innovations to
            create unique implementations
          </p>
        </div>
        {modules.map((module) => {
          return (
            <div key={module.title} className="module-card">
              <div className="module-card-player">
                <div className="module-card-yt-player">
                  <VideoCarousal video={module.link} images={module.images} />
                </div>
              </div>
              <div className="module-description">
                <h3 style={{ fontSize: "26px", color: "#03c988" }}>
                  {module.title}
                </h3>
                <div className="module-core-features">
                  <h3>Core Features</h3>
                  <ul>
                    {module.coreFeatures.map((feature) => {
                      return <li>{feature}</li>;
                    })}
                  </ul>
                </div>
                <div className="module-core-development">
                  <h3>Development</h3>
                  <ul>
                    {module.development.map((dev) => {
                      return <li>{dev}</li>;
                    })}
                  </ul>
                </div>
                <div className="module-core-further-dev">
                  <h3>Further Development</h3>
                  <ul>
                    {module.furtherDevelopment.map((fur) => {
                      return <li>{fur}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DevSeven;
