export const blogObjects = [
  {
    id: "1_nov_24",
    month: "",
    year: "",
    title: "Blog 1",
    content: [
      "Enhanced the tank's speed.",
      'Boost activated upon pressing "SHIFT.” Boost will last till 3 seconds. (Can be modified)',
      "After depleting, the boost will recharge and will take 6 seconds. (Can be modified)",
      "If the player is moving while using boost and stops, boost will pause and pick up where it left off.",
      "A progress bar has been added to the user interface to depict the process of depleting and recharging visually.",
      "The necessary variables are made available to the editor panel and made public for fast testing and use if a tank upgrade is carried out in the future. ",
    ],
  },
  {
    id: "2_nov_24",
    month: "November",
    year: "2024",
    title: "Blog 2",
    content: [
      "Created a temporary drawing of the Tank attributes upgrade menu.",
      "Translated from drawing into Unreal Engine’s Widget blueprint",
      "Used structure data types to get hold of Tank attributes",
      "Created a single slot widget which will be dynamically added to the main upgrade widget screen",
      "Used structure data types to get hold of Tank attributes",
      "Created a single slot widget, which will be dynamically added to the main upgrade widget screen according to the number of attributes.",
    ],
  },
  {
    id: "2_nov_24",
    month: "November",
    year: "2024",
    title: "Blog 3",
    content: [
      "Added currency variable to the player tank. Increased currency on killing enemy towers.",
      "Made currency gained from enemy towers public and modular",
      "Created an Upgrade Zone and added input to open the Upgrade Menu UI.",
      "Added a key highlight to indicate player to press “E” to open Upgrade Menu.",
      "Added a “Refill Health” option along with attributes.",
    ],
  },
];
