import React from "react";
import "./Work.css";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import devThree from "../../../images/dev_three/Level/3L_2.jpg";
import DevSeven from "../../../images/GameModules/CameraHacking_1.jpg";
import devSix from "../../../images/dev_six.jpg";

const Work = () => {
  const [id, setId] = useState();
  const navigate = useNavigate();

  const work_card_object = [
    {
      id: "dev_7",
      title: "Game Modules",
      image_src: DevSeven,
      project_title: "Game Modules",
      created_at: "Unreal Engine 5, C++, UMG",
      project_type: "Gameplay & UI/UX",
      project_description:
        "Here, I recreate and reimagine gameplay mechanics and UI/UX features inspired by the games I've played or watched. My approach is modular and dynamic, allowing for flexibility and originality. While I draw inspiration from existing games, my work isn't confined to replicating them—I also integrate my own ideas and innovations to create unique implementations.",
      role: "Gameplay & UI/UX Programmer",
      team_members: "Solo",
    },
    {
      id: "dev_3",
      title: "AMAZE - A Third Person Adventure",
      image_src: devThree,
      project_title: "AMAZE - A Third Person Adventure",
      created_at: "04/03/2022",
      project_type: "Development",
      project_description: `"AMAZE" is an enthralling and thought-provoking video game that immerses players in the shoes of an archaeology student on an intriguing quest. Tasked with rediscovering the enigmatic ancient Benin wall and selecting a precious artifact for analysis, the game unveils a mysterious twist. The once-familiar walls have been re-engineered, transforming them into a complex maze filled with challenges and secrets waiting to be unraveled.`,
      carousal_imageList: [],
      role: "Game Designer, Developer",
      team_members: "Oluwafemi Adewunmi and Song Zhechao.",
    },
    {
      id: "dev_6",
      title: "Blaster: A Third-Person Role-Playing Shooter",
      image_src: devSix,
      project_title: "Blaster: A Third-Person Role-Playing Shooter",
      created_at: "Unreal Engine 4, Blueprint Scripting, and C++",
      project_type: "Gameplay & UI/UX Programming",
      project_description:
        "'Blaster' is an immersive third-person role-playing shooter game developed in Unreal Engine 4. As the sole developer, I created a complex game system that demonstrates advanced gameplay mechanics, intricate AI behavior, and a robust inventory system",
      role: "Game Designer, Developer",
      team_members: "Solo",
    },
  ];
  return (
    <div className="work_container">
      <div
        className="work_card c_one"
        key={work_card_object[0].id}
        onClick={() => {
          setId(work_card_object[0].id);
          navigate(`/works/developmentdetails/dev_7`, {
            state: { object: work_card_object[0] },
          });
        }}
      >
        <div className="img-section">
          <img className="work_image" src={DevSeven} alt="" />
        </div>
        <div className="text_section">
          <h3 className="card_heading">{work_card_object[0].project_title}</h3>
          <p className="card_description">
            {work_card_object[0].project_description}
          </p>
        </div>
      </div>
      <div
        className="work_card c_two"
        key={work_card_object[1].id}
        onClick={() => {
          setId(work_card_object[1].id);
          navigate(`/works/developmentdetails/dev_3`, {
            state: { object: work_card_object[1] },
          });
        }}
      >
        <div className="text_section">
          <h3 className="card_heading">{work_card_object[1].project_title}</h3>
          <p className="card_description">
            {work_card_object[1].project_description}
          </p>
        </div>
        <div className="img-section">
          <img className="work_image" src={devThree} alt="" />
        </div>
      </div>
      <div
        className="work_card c_three"
        key={work_card_object[2].id}
        onClick={() => {
          setId(work_card_object[2].id);
          navigate(`/works/developmentdetails/dev_6`, {
            state: { object: work_card_object[2] },
          });
        }}
      >
        <div className="img-section">
          <img className="work_image" src={devSix} alt="" />
        </div>
        <div className="text_section">
          <h3 className="card_heading">{work_card_object[2].project_title}</h3>
          <p className="card_description">
            {work_card_object[2].project_description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Work;
